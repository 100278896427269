/* Define a counter for the pages */
/*@page {
  counter-reset: page;
}

!* Increment the counter on each printed page *!
.page {
  page-break-after: always;
  counter-increment: page;
}

!* Style the page number information *!
@page {
  @bottom-center {
    content: "Page " counter(page) " of " counter(pages);
  }
}*/

/* Hide the page number information on screen */

@media print {
  body * {
    visibility: hidden !important;
    overflow: visible !important;
    box-sizing: border-box;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
  }

  .cdk-overlay-pane {
    width: 100% !important;
    max-width: 100% !important;
    height: unset !important;
  }

  mdl-rhs-rehearsal-feature {
    display: contents !important;

    header,
    aside {
      display: none;
    }
  }

  mdl-gdl-guidelines,
  mdl-gdl-guidelines * {
    visibility: visible !important;
    font-size: 12px;
  }

  mdl-gdl-guidelines * {
    position: relative !important;
    width: unset;
  }

  mdl-gdl-guidelines {
    mdl-gdl-cover {
      display: block;
      break-after: page;
    }
  }

  mdl-gdl-slide-guidelines,
  mdl-gdl-slide-guidelines * {
    visibility: visible !important;
  }

  mdl-gdl-slide-guidelines * {
    position: relative !important;
    width: unset;
  }

  mdl-gdl-slide-guidelines,
  mdl-gdl-guidelines {
    position: absolute !important;
    top: 0;
    left: 0;
    height: unset !important;
    width: 100%;
    //max-width: 100vw;
    margin: 0;
    padding: 0;
    overflow: visible;
    box-sizing: border-box;
    //background-color: lightgray !important;
    //border: 1px solid red !important;
    counter-reset: pages;
    filter: none !important;
    border-radius: 0 !important;
    border: none !important;
    button {
      display: none !important;
    }
  }

  /*mdl-gdl-guidelines:after {
    content: counter(page);
    display: none;
  }*/
}
