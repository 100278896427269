/* You can add global styles to this file, and also import other style files */
@use 'reset';
@use 'functional';
@use 'sizes';
@use 'spaces';
@use 'typography';
@use 'fonts';
@use 'theme/index';
@use 'print';

@use 'theme/mixins' as mixins;

:root {
  --appFont: Rubik;
}

html,
body {
  min-height: 100%;
  color: var(--text);
  user-select: none;
  font-size: 16px;
  font-family: var(--appFont), NotoSansJA, NotoSansKR, NotoSansCS, NotoSansCT,
    system-ui, -apple-system,
    /* Firefox supports this but not yet `system-ui` */ 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  background-color: black;
  --transition-duration: 0.4s;
}

/*
.renderer-group-dd6bba7b-a436-4d87-99cf-3dd4f681521f{
  mil-typo-text-box{
    position: absolute;
    top: 200px;
    left: 200px;
  }
}
*/
