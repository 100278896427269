@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-Light.woff2') format('woff2');
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-Regular.woff2') format('woff2');
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-Medium.woff2') format('woff2');
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: 'AbhayaLibre';
  src: url('../assets/fonts/AbhayaLibre-Regular.otf') format('opentype');
  font-display: swap;
  font-weight: 400;
}


@font-face {
  font-family: 'monospace-font';
  src: url('../assets/fonts/ChivoMono-Regular.ttf') format('truetype');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'NotoSansSC';
  src: url('../assets/fonts/sc/NotoSansSC-Regular.otf') format('opentype');
  font-display: swap;
  font-weight: 400;
  unicode-range: U+4E00-9FFF, U+3400-4DBF;
}

@font-face {
  font-family: 'NotoSansKR';
  src: url('../assets/fonts/kr/NotoSansKR-Regular.otf') format('opentype');
  font-display: swap;
  font-weight: 400;
  unicode-range: U+AC00-D7AF;
}
