@mixin bgBase() {
  background: linear-gradient(to top, var(--a-900-hsl) 0%, black 100%);
}

@mixin bgToRight() {
  background: linear-gradient(
    to right,
    var(--a-900-hsl) 0%,
    var(--a-800-hsl) 100%
  );
}
