$spaces: (
  'default': 0.63rem,
  'sm': 0.31rem,
  'md': 0.94rem,
  'lg': 1.25rem,
  'xl': 1.88rem,
  '2xl': 2.5rem,
  '3xl': 3.13rem,
  '4xl': 3.75rem,
  '5xl': 4.38rem,
  '6xl': 5rem,
  '7xl': 5.63rem,
  '8xl': 6.25rem,
);

:root {
  @each $name, $space in $spaces {
    @if $name == 'default' {
      --space: #{$space};
    } @else {
      --space-#{$name}: #{$space};
    }
  }

  --app-padding-x: var(--msd-space-lg);
  --app-negative-padding-x: calc(var(--app-padding-x) * -1);

  --grid-gap-v: var(--msd-space-5xl);
  --detail-section-gap-v: var(--grid-gap-v);

  --footer-margin-top: var(--detail-section-gap-v);
}

@each $name, $space in $spaces {
  //padding
  .p-#{$name} {
    padding: #{$space};
  }
  .pl-#{$name} {
    padding-left: #{$space};
  }
  .pt-#{$name} {
    padding-top: #{$space};
  }
  .pr-#{$name} {
    padding-right: #{$space};
  }
  .pb-#{$name} {
    padding-bottom: #{$space};
  }
  .px-#{$name} {
    padding-left: #{$space};
    padding-right: #{$space};
  }
  .py-#{$name} {
    padding-top: #{$space};
    padding-bottom: #{$space};
  }
  //margin
  .m-#{$name} {
    margin: #{$space};
  }
  .ml-#{$name} {
    margin-left: #{$space};
  }
  .mt-#{$name} {
    margin-top: #{$space};
  }
  .mr-#{$name} {
    margin-right: #{$space};
  }
  .mb-#{$name} {
    margin-bottom: #{$space};
  }
  .mx-#{$name} {
    margin-left: #{$space};
    margin-right: #{$space};
  }
  .my-#{$name} {
    margin-top: #{$space};
    margin-bottom: #{$space};
  }
  //gap
  .gap-#{$name} {
    gap: #{$space};
  }
}
