@use '../../../../../node_modules/@angular/cdk/overlay-prebuilt.css';
.cdk-overlay-backdrop {
  background: transparent;
  //backdrop-filter: blur(10px);
}

.cdk-overlay-pane,
cdk-dialog-container {
  outline: none;
}

.rocket-overlay {
  backdrop-filter: blur(7px);
  background-color: hsla(var(--a-900), 0.25);
}

.cdk-overlay-backdrop.guidelines {
  backdrop-filter: blur(10px) saturate(0.25);
}

.cdk-overlay-pane.trigger-first-interaction {
  mdl-dlg-window {
    padding: 0 !important;
  }
}
