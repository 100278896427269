@mixin generateThemeVars($varPrefix) {
  $colorValues: 50, 100, 200, 300, 400, 500, 600, 700, 800, 900;
  @each $name in $colorValues {
    --#{$varPrefix}-#{$name}-hsl: hsl(
      var(--#{$varPrefix}-h-#{$name}),
      var(--#{$varPrefix}-s-#{$name}),
      var(--#{$varPrefix}-l-#{$name})
    );
  }
}

@mixin applyTheme($varPrefix) {
  $colorValues: 50, 100, 200, 300, 400, 500, 600, 700, 800, 900;
  @each $name in $colorValues {
    --a-#{$name}: var(--#{$varPrefix}-h-#{$name}),
      var(--#{$varPrefix}-s-#{$name}), var(--#{$varPrefix}-l-#{$name});

    --a-#{$name}-hsl: hsl(
      var(--#{$varPrefix}-h-#{$name}),
      var(--#{$varPrefix}-s-#{$name}),
      var(--#{$varPrefix}-l-#{$name})
    );

    --#{$varPrefix}-#{$name}-hsl: hsl(
      var(--#{$varPrefix}-h-#{$name}),
      var(--#{$varPrefix}-s-#{$name}),
      var(--#{$varPrefix}-l-#{$name})
    );

    --a-#{$name}-contrast: var(--#{$varPrefix}-text-#{$name});
  }
}

$luminosityDarkMap: (
  '50': 95%,
  '100': 90%,
  '200': 80%,
  '300': 70%,
  '400': 60%,
  '500': 50%,
  '600': 40%,
  '700': 30%,
  '800': 20%,
  '900': 10%,
);
$luminosityLightMap: (
  '50': 10%,
  '100': 20%,
  '200': 30%,
  '300': 40%,
  '400': 50%,
  '500': 60%,
  '600': 70%,
  '700': 80%,
  '800': 90%,
  '900': 95%,
);

$textAccentContrastMap: (
  '50': var(--text-invert),
  '100': var(--text-invert),
  '200': var(--text-invert),
  '300': var(--text),
  '400': var(--text),
  '500': var(--text),
  '600': var(--text),
  '700': var(--text),
  '800': var(--text),
  '900': var(--text),
);

:root {
  --theme-module-hue: 265;
  --theme-module-saturation: 100%;
  --theme-module-h-50: var(--theme-module-hue);
  --theme-module-h-100: var(--theme-module-hue);
  --theme-module-h-200: var(--theme-module-hue);
  --theme-module-h-300: var(--theme-module-hue);
  --theme-module-h-400: var(--theme-module-hue);
  --theme-module-h-500: var(--theme-module-hue);
  --theme-module-h-600: var(--theme-module-hue);
  --theme-module-h-700: var(--theme-module-hue);
  --theme-module-h-800: var(--theme-module-hue);
  --theme-module-h-900: var(--theme-module-hue);
  --theme-module-s-50: var(--theme-module-saturation);
  --theme-module-s-100: var(--theme-module-saturation);
  --theme-module-s-200: var(--theme-module-saturation);
  --theme-module-s-300: var(--theme-module-saturation);
  --theme-module-s-400: var(--theme-module-saturation);
  --theme-module-s-500: var(--theme-module-saturation);
  --theme-module-s-600: var(--theme-module-saturation);
  --theme-module-s-700: var(--theme-module-saturation);
  --theme-module-s-800: var(--theme-module-saturation);
  --theme-module-s-900: var(--theme-module-saturation);
  --theme-module-l-50: 95%;
  --theme-module-l-100: 90%;
  --theme-module-l-200: 80%;
  --theme-module-l-300: 70%;
  --theme-module-l-400: 60%;
  --theme-module-l-500: 50%;
  --theme-module-l-600: 40%;
  --theme-module-l-700: 30%;
  --theme-module-l-800: 20%;
  --theme-module-l-900: 10%;

  --theme-module-text: var(--a-50-hsl);
  --theme-module-text-invert: var(--a-900-hsl);

  --text: var(--theme-module-text);
  --text-invert: var(--theme-module-text-invert);

  --theme-player-hue: var(--theme-module-hue);
  --theme-player-saturation: var(--theme-module-saturation);
  --theme-player-h-50: var(--theme-player-hue);
  --theme-player-h-100: var(--theme-player-hue);
  --theme-player-h-200: var(--theme-player-hue);
  --theme-player-h-300: var(--theme-player-hue);
  --theme-player-h-400: var(--theme-player-hue);
  --theme-player-h-500: var(--theme-player-hue);
  --theme-player-h-600: var(--theme-player-hue);
  --theme-player-h-700: var(--theme-player-hue);
  --theme-player-h-800: var(--theme-player-hue);
  --theme-player-h-900: var(--theme-player-hue);
  --theme-player-s-50: var(--theme-player-saturation);
  --theme-player-s-100: var(--theme-player-saturation);
  --theme-player-s-200: var(--theme-player-saturation);
  --theme-player-s-300: var(--theme-player-saturation);
  --theme-player-s-400: var(--theme-player-saturation);
  --theme-player-s-500: var(--theme-player-saturation);
  --theme-player-s-600: var(--theme-player-saturation);
  --theme-player-s-700: var(--theme-player-saturation);
  --theme-player-s-800: var(--theme-player-saturation);
  --theme-player-s-900: var(--theme-player-saturation);
  --theme-player-l-50: 95%;
  --theme-player-l-100: 90%;
  --theme-player-l-200: 80%;
  --theme-player-l-300: 70%;
  --theme-player-l-400: 60%;
  --theme-player-l-500: 50%;
  --theme-player-l-600: 40%;
  --theme-player-l-700: 30%;
  --theme-player-l-800: 20%;
  --theme-player-l-900: 10%;

  --theme-player-text: var(--theme-module-text);
  --theme-player-text-invert: var(--a-900-hsl);

  --theme-storyboard-hue: var(--theme-module-hue);
  --theme-storyboard-saturation: var(--theme-module-saturation);
  --theme-storyboard-h-50: var(--theme-storyboard-hue);
  --theme-storyboard-h-100: var(--theme-storyboard-hue);
  --theme-storyboard-h-200: var(--theme-storyboard-hue);
  --theme-storyboard-h-300: var(--theme-storyboard-hue);
  --theme-storyboard-h-400: var(--theme-storyboard-hue);
  --theme-storyboard-h-500: var(--theme-storyboard-hue);
  --theme-storyboard-h-600: var(--theme-storyboard-hue);
  --theme-storyboard-h-700: var(--theme-storyboard-hue);
  --theme-storyboard-h-800: var(--theme-storyboard-hue);
  --theme-storyboard-h-900: var(--theme-storyboard-hue);
  --theme-storyboard-s-50: var(--theme-storyboard-saturation);
  --theme-storyboard-s-100: var(--theme-storyboard-saturation);
  --theme-storyboard-s-200: var(--theme-storyboard-saturation);
  --theme-storyboard-s-300: var(--theme-storyboard-saturation);
  --theme-storyboard-s-400: var(--theme-storyboard-saturation);
  --theme-storyboard-s-500: var(--theme-storyboard-saturation);
  --theme-storyboard-s-600: var(--theme-storyboard-saturation);
  --theme-storyboard-s-700: var(--theme-storyboard-saturation);
  --theme-storyboard-s-800: var(--theme-storyboard-saturation);
  --theme-storyboard-s-900: var(--theme-storyboard-saturation);
  --theme-storyboard-l-50: 95%;
  --theme-storyboard-l-100: 90%;
  --theme-storyboard-l-200: 80%;
  --theme-storyboard-l-300: 70%;
  --theme-storyboard-l-400: 60%;
  --theme-storyboard-l-500: 50%;
  --theme-storyboard-l-600: 40%;
  --theme-storyboard-l-700: 30%;
  --theme-storyboard-l-800: 20%;
  --theme-storyboard-l-900: 10%;

  --theme-storyboard-text: var(--theme-module-text);
  --theme-storyboard-text-invert: var(--theme-module-text-invert);

  --theme-app-hue: var(--theme-module-hue);
  --theme-app-saturation: 0%;
  --theme-app-h-50: var(--theme-app-hue);
  --theme-app-h-100: var(--theme-app-hue);
  --theme-app-h-200: var(--theme-app-hue);
  --theme-app-h-300: var(--theme-app-hue);
  --theme-app-h-400: var(--theme-app-hue);
  --theme-app-h-500: var(--theme-app-hue);
  --theme-app-h-600: var(--theme-app-hue);
  --theme-app-h-700: var(--theme-app-hue);
  --theme-app-h-800: var(--theme-app-hue);
  --theme-app-h-900: var(--theme-app-hue);
  --theme-app-s-50: var(--theme-app-saturation);
  --theme-app-s-100: var(--theme-app-saturation);
  --theme-app-s-200: var(--theme-app-saturation);
  --theme-app-s-300: var(--theme-app-saturation);
  --theme-app-s-400: var(--theme-app-saturation);
  --theme-app-s-500: var(--theme-app-saturation);
  --theme-app-s-600: var(--theme-app-saturation);
  --theme-app-s-700: var(--theme-app-saturation);
  --theme-app-s-800: var(--theme-app-saturation);
  --theme-app-s-900: var(--theme-app-saturation);
  --theme-app-l-50: 95%;
  --theme-app-l-100: 90%;
  --theme-app-l-200: 80%;
  --theme-app-l-300: 70%;
  --theme-app-l-400: 60%;
  --theme-app-l-500: 50%;
  --theme-app-l-600: 40%;
  --theme-app-l-700: 30%;
  --theme-app-l-800: 20%;
  --theme-app-l-900: 10%;

  --theme-app-text: var(--theme-module-text);
  --theme-app-text-invert: var(--theme-module-text-invert);

  --app-bg: var(--a-900-hsl);

  @include generateThemeVars(theme-module);
  @include generateThemeVars(theme-player);
  @include generateThemeVars(theme-storyboard);
  @include generateThemeVars(theme-app);

  @include applyTheme(theme-module);
}

mdl-program-program-player,
mdl-rhs-rehearsal-feature,
lib-co-pilot {
  --timeline-track-bg: var(--a-700-hsl);
  --timeline-progress-bg: var(--a-400-hsl);
  --timeline-h: 5px;
  --marker-border-color: var(--a-100-hsl);
  --text: var(--theme-player-text);
  --text-invert: var(--theme-player-text-invert);
  color: var(--text);
  @include applyTheme(theme-player);
}

nx-modulae-files-explorer,
mdl-stb-storyboard {
  --text: var(--theme-storyboard-text);
  --text-invert: var(--theme-storyboard-text-invert);
  color: var(--text);
  @include applyTheme(theme-storyboard);
}

mdl-ui-app-menu {
  @include applyTheme(theme-module);
}
