$font-sizes: (
  'xs': 0.75rem,
  'sm': 0.88rem,
  'base': 1rem,
  'md': 1.13rem,
  'lg': 1.25rem,
  'xl': 1.5rem,
  '2xl': 2.5rem,
);
$font-weights: (
  'light': 300,
  'normal': 400,
  'bold': 500,
);

:root {
  --text-base: 16;

  @each $name, $size in $font-sizes {
    --text-#{$name}: #{$size};
  }

  @each $name, $weight in $font-weights {
    --font-#{$name}: #{$weight};
  }

  --letter-spacing: var(--one-pixel);
}

@each $name, $size in $font-sizes {
  .text-#{$name} {
    font-size: #{$size};
  }
}

@each $name, $weight in $font-weights {
  .font-#{$name} {
    font-weight: #{$weight};
  }
}

@for $i from 1 through 6 {
  h#{$i} {
    font-weight: 400;
  }
}

.absolute-font-size {
  font-size: calc(1px * var(--text-base));
}
