a.btn {
  cursor: pointer;
}

.btn {
  --button-height: var(--btn-h);
  --gap: var(--space);

  &.small {
    --button-height: var(--btn-h-small);
  }

  &.big {
    --button-height: var(--btn-h-big);
    --gap: var(--space-md);
  }

  position: relative;
  height: var(--button-height);
  line-height: var(--button-height);
  border-radius: calc(var(--button-height) / 2);
  border: none;
  display: flex;
  flex: none;
  text-transform: uppercase;
  align-items: center;
  padding: 0 calc(var(--button-height) / 2);
  background-color: transparent;
  gap: var(--gap);
  font-size: var(--text-sm);
  font-weight: 700;

  &:enabled {
    cursor: pointer;
  }

  pointer-events: auto;

  color: var(--text);

  &.icon {
    padding: 0;
    width: var(--button-height);
    justify-content: center;
  }

  &.notch-right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;

    --button-height: var(--size-md);
    --button-width: var(--size-xl);

    &.small {
      --button-height: var(--size-sm);
      --button-width: var(--size-md);
    }

    &.big {
      --button-height: var(--size-2xl);
      --button-width: var(--size-3xl);
    }

    width: var(--button-width);
    height: var(--button-height);
    justify-content: flex-start;
    align-items: center;
    padding: 0 calc(var(--button-height) / 3) 0 0;
  }

  &.notch-left {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: none;

    --button-height: var(--size-md);
    --button-width: var(--size-xl);

    &.small {
      --button-height: var(--size-sm);
      --button-width: var(--size-md);
    }

    &.big {
      --button-height: var(--size-2xl);
      --button-width: var(--size-3xl);
    }

    width: var(--button-width);
    height: var(--button-height);
    justify-content: flex-end;
    align-items: center;
    padding: 0 calc(var(--button-height) / 3) 0 0;
  }

  &.notch-bottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;

    --button-height: var(--size-xl);
    --button-width: var(--size-md);

    &.small {
      --button-height: var(--size-md);
      --button-width: var(--size-sm);
    }

    &.big {
      --button-height: var(--size-4xl);
      --button-width: var(--size-2xl);
    }

    width: var(--button-width);
    align-items: flex-start;
    justify-content: center;
    padding: calc(var(--button-width) / 3) 0 0 0;
  }

  &.stroked.accent {
    border: 1px solid var(--a-500-hsl);
    background: transparent !important;
  }

  transition-property: transform;
  transition-duration: 0.4s;

  &.accent {
    &:disabled {
      opacity: 0.5;
    }

    transition-property: background, border, transform;
    transition-duration: 0.4s;

    background: linear-gradient(
      to top,
      var(--a-500-hsl) 0%,
      var(--a-400-hsl) 100%
    );
    border: 1px solid var(--a-500-hsl);
    z-index: 1;

    color: var(--a-500-contrast);

    //&:enabled {
    &:hover {
      --top-gradient-color: var(--a-400-hsl);
      --bottom-gradient-color: var(--a-300-hsl);
      --border-color: var(--a-400-hsl);
      --action-duration: 0.4s;

      &:before {
        opacity: 1;
      }
    }

    &:active {
      --top-gradient-color: var(--a-600-hsl);
      --bottom-gradient-color: var(--a-500-hsl);
      --border-color: var(--a-600-hsl);
      --action-duration: 0s;

      &:before {
        opacity: 1;
      }
    }

    //}

    &:before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: inherit;
      background: linear-gradient(
        to top,
        var(--top-gradient-color) 0%,
        var(--bottom-gradient-color) 100%
      );
      border: 1px solid var(--border-color);
      opacity: 0;
      z-index: -1;
      transition: opacity var(--action-duration) linear;
    }
  }

  &.warn {
    background-color: hsla(4, 90%, 30%, 100%);
  }

  &.toggle {
    transition-property: color;
    transition-duration: 0.4s;

    color: hsla(var(--a-50), 70%);

    &.selected {
      color: var(--a-300-hsl);
    }

    &:hover {
      color: var(--a-200-hsl);
    }
  }

  outline: none;

  &:focus,
  &:has(:focus-visible) {
    //transform: scale(1.05);

    &.accent {
      --top-gradient-color: var(--a-400-hsl);
      --bottom-gradient-color: var(--a-300-hsl);
      --border-color: var(--a-400-hsl);
      --action-duration: 0.4s;

      &:before {
        opacity: 1;
      }
    }
  }
}

.focus-element {
  transition-property: transform;
  transition-duration: 0.4s;
  outline: none;

  &:focus {
    transform: scale(1.05);
  }
}
