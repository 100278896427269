@use 'mixins' as mixins;

.bg-toc {
  background: linear-gradient(
    to top,
    hsla(var(--a-900), 0.8) 0%,
    hsla(var(--a-800), 0.8) 100%
  );
}

.player-controls {
  //@include mixins.bgBase();
  //border-top: solid 1px var(--a-500-hsl);
}
