@mixin buildSizeVars($map, $varPrefix) {
  @each $name, $size in $map {
    @if $name == 'default' {
      --#{$varPrefix}: #{$size};
      .#{$varPrefix} {
        height: #{$size};
      }
    } @else {
      --#{$varPrefix}-#{$name}: #{$size};
      .#{$varPrefix}-#{$name} {
        height: #{$size};
      }
    }
  }
}

$base-sizes: (
  'default': 1.88rem,
  'xs': 0.63rem,
  'sm': 1.25rem,
  'md': 2.5rem,
  'lg': 3.13rem,
  'xl': 3.75rem,
  '2xl': 4.38rem,
  '3xl': 5rem,
  '4xl': 5.63rem,
  '5xl': 6.25rem,
  '6xl': 7.5rem,
  '7xl': 8.75rem,
  '8xl': 10rem,
  '9xl': 11.25rem,
  '10xl': 12.5rem,
);
$button-height: (
  'default': var(--size-md),
  'small': var(--size-sm),
  'big': var(--size-2xl),
);
:root,
:before,
:after {
  @each $name, $size in $base-sizes {
    @if $name == 'default' {
      --size: #{$size};
    } @else {
      --size-#{$name}: #{$size};
    }
  }

  @include buildSizeVars($button-height, btn-h);

  --one-pixel: 0.0625rem;

  --toc-width: 14.375em;

  --window-header-height: 60px;
}

@each $name, $size in $button-height {
  .h-btn-#{$name} {
    height: #{$size};
  }
}

@each $name, $size in $base-sizes {
  .w-#{$name} {
    width: #{$size};
  }
  .h-#{$name} {
    height: #{$size};
  }
  .min-w-#{$name} {
    min-width: #{$size};
  }
  .min-h-#{$name} {
    min-height: #{$size};
  }
  .max-w-#{$name} {
    max-width: #{$size};
  }
  .max-h-#{$name} {
    max-height: #{$size};
  }
}
